const banner = document.querySelector('.cookie-consent')

const hideBanner = () => {
    if (banner) {
        banner.style.display = 'none'
    }
}

const setConsent = consent => {
    const { necessary, analytics, preferences, marketing } = consent
    const grantedOrDenied = value => value ? 'granted' : 'denied'

    const consentMode = {
        /* eslint-disable camelcase */
        functionality_storage: grantedOrDenied(necessary),
        security_storage: grantedOrDenied(necessary),
        ad_storage: grantedOrDenied(marketing),
        analytics_storage: grantedOrDenied(analytics),
        personalization: grantedOrDenied(preferences),
        /* eslint-enable */
    }

    window.dataLayer = window.dataLayer || []
    // eslint-disable-next-line no-undef
    dataLayer.push({
        event: 'consent_update',
        consent: consent,
    })

    localStorage.setItem('consentMode', JSON.stringify(consentMode))
}

document.addEventListener('DOMContentLoaded', () => {
    if (localStorage.getItem('consentMode')) {
        setConsent(JSON.parse(localStorage.getItem('consentMode')))
        hideBanner()
    } else {
        const acceptAllBtn = document.getElementsByClassName('btn-accept-all')
        const acceptSomeBtn = document.getElementsByClassName('btn-accept-some')
        const rejectAllBtn = document.getElementsByClassName('btn-reject-all')

        if (acceptAllBtn.length > 0) {
            acceptAllBtn[0].addEventListener('click', (e) => {
                e.preventDefault()
                setConsent({
                    necessary: true,
                    analytics: true,
                    preferences: true,
                    marketing: true,
                })
                hideBanner()
            })
        }

        if (acceptSomeBtn.length > 0) {
            acceptSomeBtn[0].addEventListener('click', (e) => {
                e.preventDefault()
                setConsent({
                    necessary: true,
                    analytics: true,
                    preferences: false,
                    marketing: false,
                })
                hideBanner()
            })
        }

        if (rejectAllBtn.length > 0) {
            rejectAllBtn[0].addEventListener('click', (e) => {
                e.preventDefault()
                setConsent({
                    necessary: false,
                    analytics: false,
                    preferences: false,
                    marketing: false,
                })
                hideBanner()
            })
        }

        banner.style.display = 'block'
    }
})
